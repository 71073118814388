import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/TROISPOINTZERO/pecheur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pêcheurcom-app-by-troispointzéro"
    }}>{`Pêcheur.com app (by `}<a parentName="h1" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{`)`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Auteur : Jordan Protin | Développeur informatique chez TroisPointZéro | `}<a parentName="p" {...{
          "href": "mailto:jordan@tpz.fr"
        }}>{`jordan@tpz.fr`}</a></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Dernière mise à jour : vendredi 29 janvier 2021`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Un problème ? Veuillez contacter l'auteur de cette documentation !`}</p>
    </blockquote>
    <h2 {...{
      "id": "où-trouver-lapplication-pêcheurcom-"
    }}>{`Où trouver l'application pêcheur.com ?`}</h2>
    <p>{`L'application mobile est disponible :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://play.google.com/store/apps/details?id=com.pecheur"
        }}>{`sur le google play Android`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/p%C3%AAcheur-com/id1469563134"
        }}>{`sur l'apple store iOS`}</a></li>
    </ul>
    <h2 {...{
      "id": "à-propos"
    }}>{`À propos`}</h2>
    <p>{`Le branding est l'un des principaux objectifs de cette application mobile pêcheur.com :`}</p>
    <ul>
      <li parentName="ul">{`assurer une présence sur les différents stores`}</li>
      <li parentName="ul">{`augmenter la fidélité des utilisateurs`}</li>
      <li parentName="ul">{`développer une stratégie marketing relationnel`}</li>
      <li parentName="ul">{`établir un nouveau mode de communication basé sur le conseil et le relai de contenu`}</li>
      <li parentName="ul">{`améliorer l'accessibilité au site`}</li>
      <li parentName="ul">{`limiter les dépenses marketing`}</li>
      <li parentName="ul">{`acquérir de nouveaux clients via un nouveau canal`}</li>
    </ul>
    <p>{`Cible visée :`}</p>
    <ul>
      <li parentName="ul">{`les CLIENTS : ceux qui ont déjà un profil et qui commandent régulièrement sur le site (fidélisation)`}</li>
      <li parentName="ul">{`les NOUVEAUX utilisateurs : qui vont découvrir la marque au travers de l'application (acquisition)`}</li>
      <li parentName="ul">{`les FANS : adeptent des réseaux, ce public recherche surtout le contenu et des conseils (branding et marketing)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      